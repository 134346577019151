.App {
  text-align: center;
}

.ion-button-money {
  --background: #3f90b0;
  --box-shadow: "none"
}
.mainDiv {
  margin-left: 10px;
}

.ion-button-inverse {
  --border-style: solid;
  --border-width: 2px;
  --border-color: #3f90b0;
  --color: #3f90b0;
  --background: white;
  --background-hover: grey;
  --box-shadow: "none"
}

.continue-modal .modal-wrapper {
  width: 400px;
  height: fit-content;
  border-radius: 5px;
  overflow-y: hidden;
}

.input-container {
  --padding-start: 5;
  --inner-padding-end: 0;
  --inner-padding-start: 5px;
  font-size: 14px;
  --background-hover: transparent;
  --highlight-color-focused: #3f90b0;
  --highlight-height: 0;
  --ion-color-primary: #3f90b0;
  border: 1px solid #3f90b0;
  border-radius: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media only screen and (max-width: 450px) {
  /* body {
    background-color: lightblue;
  } */
  .continue-modal .modal-wrapper {
    width: 300px;
    height: fit-content;
    border-radius: 5px;
  }
  .mainDiv {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.backdrop-no-scroll{
  overflow-y: hidden;
}

/* .short-item ion-item  {
  --min-height: 20px !important;
  height: 20px !important;
  min-height: 20px !important;
} */
 ion-item {
  --min-height: 10px;

}

ion-radio {
  margin-right: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
}
ion-label {
  margin-top: 0px;
  margin-bottom: 0px;
}

ion-modal {
  height: 100vh;
  overflow-y: hidden;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
